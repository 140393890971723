import React from 'react'

function ResumeBuilder() {
  return (
    <div>ResumeBuilder</div>
  )
}

export default ResumeBuilder




